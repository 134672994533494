<template>
  <div class="order-list-wrapper">
    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading">
      <template slot="toolbar" style="width: 100%;">
        <div style="display: flex;">
          <el-form-item label="导入时间">
            <el-date-picker style="width: 220px" v-model="order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" @change="handleDateChange"></el-date-picker>
          </el-form-item>
          <el-form-item label="导入批次号">
            <el-input v-model="params.batch_sn" placeholder="请输入导入批次号" size="medium" clearable></el-input>
          </el-form-item>
          <el-form-item label="导入单状态">
            <el-select v-model="params.status" placeholder="请选择" clearable style="width:110px;" size="small">
              <el-option label="全部" value></el-option>
              <el-option label="待支付" :value="0"></el-option>
              <el-option label="已支付" :value="1"></el-option>
              <el-option label="已取消" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <div class="col-auto">
            <el-button @click.prevent="searchEvent" size="small" native-type="submit" type="primary">
              搜索
            </el-button>
          </div>
        </div>
      </template>
      <template slot="table-columns">

        <el-table-column label="导入批次号" prop="batch_sn" fixed="left" width="130px"></el-table-column>
        <el-table-column label="导入时间" prop="create_time" fixed="left" width="160px">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="订单数量" prop="order_num" fixed="left" width="100px"></el-table-column>
        <el-table-column label="商品金额总计" prop="goods_price"></el-table-column>
        <el-table-column label="运费金额总计" prop="freight_price"></el-table-column>
        <el-table-column label="订单金额总计" prop="order_price"></el-table-column>
        <el-table-column label="导入订单状态" prop="status">
          <template slot-scope="{row}">
            {{ row.status | importStatusFilter }}
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="op_name"></el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <div slot-scope="scope" style="display: flex;">
            <div v-if="!scope.row.status">
              <el-button style="margin-left: 5px;" size="mini" type="primary"
                @click="confirm(scope.row)">确认支付</el-button>
              <el-button style="margin-left: 5px;" size="mini" type="primary" @click="cancel(scope.row)">取消</el-button>
            </div>
            <el-button v-else style="margin-left: 5px;" size="mini" type="primary"
              @click="detail(scope.row)">查看详情</el-button>
          </div>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
  </div>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import { getBatchList, batchCancelTrade } from '@/api/importOrder';
export default {
  name: 'ManagerOneClickDeliveryImportOrderList',
  components: {
    EnTableLayout,
  },
  data () {
    return {
      order_time_range: [],
      params: {
        page_no: 1,
        page_size: 20,
        batch_sn: undefined, // 导入批次号
        start_time: undefined, //开始时间
        end_time: undefined, //结束时间
        status: undefined // 0:待支付 1：已支付 2：已取消
      },
      pageData: { data: [] },
      loading: false
    };
  },

  mounted () {
    this.getBatchList()
  },
  filters: {
    importStatusFilter (value) {
      return ['待支付', '已支付', '已取消'][value]
    }
  },
  methods: {
    searchEvent () {
      this.params.page_no = 1
      this.getBatchList()
    },
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.params.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.params.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },
    async getBatchList () {
      const params = {
        ...this.params
      }
      const res = await getBatchList(params)
      this.pageData = res.data
    },
    confirm (row) {
      this.$router.push({
        path: '/order/importOrderConfirm',
        query: { batch_sn: row.batch_sn }
      })
    },
    async cancel ({ batch_sn }) {
      try {
        await this.$confirm('确认要取消该导入单吗，取消后导入单中所有订单均将取消', '提示', { type: 'warning' })
        const res = await batchCancelTrade({ batch_sn })
        this.getBatchList()
      } catch (error) {

      }
    },
    detail ({ batch_sn }) {
      console.log(batch_sn);
      this.$router.push({
        name: 'importOrderDetail',
        query: { batch_sn }
      });
    },
    // 每页数量变更
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getBatchList();
    },
    // 页数变更
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getBatchList()
    },
  },
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.content {
  background-color: #fff;

  // min-height: 90vh;
  .el-tabs__header {
    margin-bottom: 0;
  }

  .step1 {
    ::v-deep .el-step__head {
      border-color: #303133;
    }

    ::v-deep .el-step__title {
      color: #303133;
      font-weight: bold;
    }
  }
}

.toolbar-title {
  height: 25.5px;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 4px;
  margin-right: 12px;

  &::before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #1A43A9;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(0, -50%);
  }
}
</style>